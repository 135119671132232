.wallpaper-logo-industry {
    background-image: url('../assets/img/brand/wallpaper_login.webp');
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
    font-family: 'Lato', sans-serif;
}

.logo-landing-footer{
     background-color: #ffffff55;
    border-radius: 25px;
    border: 1px solid #ffffff0b;
    box-shadow: 10px 5px 5px black;
    height: 4rem;
    max-width: 500px;
    width: 100%;
    display: flex;
    padding: 0.4rem 0.8rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.logo-landing-footer img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.container-logo-landing-footer-left {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.logo-landing-footer-left{
  width:200px;
  border: 1px solid #ffffff0b;
  background-color: #ffffff44;
  border-radius: 25px;
  padding: 2rem 3rem;
  box-shadow: 10px 5px 5px black;

}


@media (min-width: 767px) {
  .container-logo-landing-footer-left{
    justify-content: start;
  }
    .left-footer-col {
        text-align: left !important;
    }
    .right-footer-col {
        text-align: right !important;
    }
}
